// Thanks to Google Charts
const COLORS = [
    '#3366CC'
  , '#DC3912'
  , '#FF9900'
  , '#109618'
  , '#990099'
  , '#575acb'
  , '#ebdb0c'
  , '#ed2467'
  , '#66AA00'
  , '#B82E2E'
  , '#316395'
  , '#994499'
  , '#22AA99'
  , '#AAAA11'
  , '#6633CC'
  , '#E67300'
  , '#8B0707'
  , '#329262'
  , '#5574A6'
  , '#3B3EAC'
]
export default COLORS
