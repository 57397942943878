import React from 'react'
import prop from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import { fromLoadable } from '../helpers/to-loadable'
import Grants from '../components/Grants'

const GRANTS = [
  {
    data: {
      id: 1,
      categoryID: 1,
      applicants: [1],
      status: 'SUBMITTED', // , 'ACCEPTED', 'FINISHED'
      name: 'Cancer Research Fund',
      fields: [],
      start: '2023-01-01',
      end: '2024-01-01',
      total: 1000000,
      cofunding: 1000000,
    }
  },
  {
    data: {
      id: 2,
      categoryID: 1,
      applicants: [1],
      status: 'SUBMITTED', // , 'ACCEPTED', 'FINISHED'
      name: 'Sickle cells research',
      fields: [],
      start: '2023-03-01',
      end: '2024-01-01',
      total: 200000,
      cofunding: 0,
    }
  },
  {
    data: {
      id: 3,
      categoryID: 1,
      applicants: [1],
      status: 'SUBMITTED', // , 'ACCEPTED', 'FINISHED'
      name: 'Epigenome variants',
      fields: [],
      start: '2023-09-01',
      end: '2025-01-01',
      total: 200000,
      cofunding: 0,
    }
  },
  {
    data: {
      id: 10,
      categoryID: 2,
      applicants: [1],
      status: 'SUBMITTED', // , 'ACCEPTED', 'FINISHED'
      name: 'Ste-Justine grant',
      fields: [],
      start: '2023-06-01',
      end: '2025-06-01',
      total: 5000000,
      cofunding: 2500000,
    }
  },
  {
    data: {
      id: 11,
      categoryID: 2,
      applicants: [1],
      status: 'SUBMITTED', // , 'ACCEPTED', 'FINISHED'
      name: '1000 Genomes project',
      fields: [],
      start: '2024-01-01',
      end: '2026-06-01',
      total: 1000000,
      cofunding: 0,
    }
  },
  {
    data: {
      id: 12,
      categoryID: 2,
      applicants: [1],
      status: 'SUBMITTED', // , 'ACCEPTED', 'FINISHED'
      name: 'Illumina purchase',
      fields: [],
      start: '2024-08-01',
      end: '2026-06-01',
      total: 1000000,
      cofunding: 0,
    }
  },
]

const FUNDINGS = [
  { data: { id: 1, fromGrantID: 1, toGrantID: 2, amount: 200000, } },
  { data: { id: 2, fromGrantID: 1, toGrantID: 3, amount: 200000, } },
  { data: { id: 3, fromGrantID: 10, toGrantID: 11, amount: 200000, } },
  { data: { id: 4, fromGrantID: 10, toGrantID: 12, amount: 200000, } },
]

const APPLICANTS = {
  data: {
    1: {
      data: {
        id: 1,
        name: 'Rom Grk',
      }
    }
  }
}

const CATEGORIES = {
  data: {
    1: {
      data: {
        id: 1,
        name: 'C3G',
        color: '#3366CC',
      }
    },
    2: {
      data: {
        id: 2,
        name: 'Hospital',
        color: '#FF9900',
      }
    }
  }
}


class GrantsContainer extends React.Component {
  static propTypes = {
    ui: prop.object.isRequired,
    grants: prop.object.isRequired,
    applicants: prop.object.isRequired,
    fundings: prop.object.isRequired,
    categories: prop.object.isRequired,
    users: prop.object.isRequired,
  }

  render() {
    return (
      <Grants
        isLoading={false}
        isCreating={this.props.grants.isCreating}
        grants={GRANTS}
        ui={this.props.ui}
        applicants={APPLICANTS}
        fundings={FUNDINGS}
        categories={CATEGORIES}
        users={this.props.users}
      />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  ui: createSelector(state => state.ui, state => state),
  grants: createSelector(state => state.grants, state => state),
  applicants: createSelector(state => ({
    isLoading: state.applicants.isLoading,
    data: state.applicants.data,
  }), state => state),
  fundings: createSelector(state => ({
    isLoading: state.fundings.isLoading,
    data: state.fundings.data,
  }), state => state),
  categories: createSelector(state => ({
    isLoading: state.categories.isLoading,
    data: state.categories.data,
  }), state => state),
  users: createSelector(state => ({
    isLoading: state.users.isLoading,
    data: fromLoadable(state.users.data),
  }), state => state),
})

export default connect(mapStateToProps)(GrantsContainer)
