/*
 * alphabetical-sort.js
 */


export default function alphabeticalSort(list, selector = x => x) {
  return list.sort((a, b) => {
    const sa = selector(a)
    const sb = selector(b)
    if (!sa || !sb) {
      debugger
      selector(a)
      selector(b)
    }
    return sa.localeCompare(sb)
  })
}
