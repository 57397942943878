/*
 * status.js
 */

export default {
  SUBMITTED:       'SUBMITTED',
  ACCEPTED:        'ACCEPTED',
  FINISHED:        'FINISHED',
  NOT_ACCEPTED:    'NOT_ACCEPTED',
}
